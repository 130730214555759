import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from './local.service';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';

import {
  Plugins,
  StatusBarStyle,
} from '@capacitor/core';

const { SplashScreen, StatusBar } = Plugins;

import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  current_language: any;

  public selectedIndex = 1;
  public appPages = [
    {
      title: 'Mein Restaurant',
      title_en: 'My Restaurant',
      url: '/restaurant',
      icon: 'pizza'
    },
    {
      title: 'Über Navitas',
      title_en: 'About Navitas',
      url: '/about',
      icon: 'star'
    },
    {
      title: 'Einstellungen',
      title_en: 'Settings',
      url: '/preferences',
      icon: 'cog'
    },
    {
      title: 'Impressum',
      title_en: 'Imprint',
      url: '/imprint',
      icon: 'document-text'
    },
    {
      title: 'Datenschutz',
      title_en: 'Privacy',
      url: '/privacy',
      icon: 'accessibility'
    }
  ];

  constructor(
    private platform: Platform,
    public _t: LocalService,
    private _translate: TranslateService,
    private router: Router,
    public sharedService: SharedService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      moment.locale('de');
      SplashScreen.hide();
      StatusBar.setStyle({ style: StatusBarStyle.Light });
    });
  }

  updateMenuState(state: boolean) {
    console.log("Update Menu Status!");
    this.sharedService.updateMenuState(state)
  }

  async translate() {
    const lang = await this._t.getLanguage();
    this.current_language = lang;
    this._translate.use(lang);
  }

  changeLanguage(lang) {
    this.current_language = lang;
    this._t.changeLanguage(lang);
    this.translate();
    console.log('this.router.url', this.router.url);
    this.router.navigate([this.router.url]);
    // console.log('this.router.url', this.router.url);
  }

  ngOnInit() {
    const path = 'restaurant';
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.translate();
  }
}
