import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class SharedService {

 public menuClosed = new BehaviorSubject(true); // set whatever default value;

 updateMenuState(state: boolean) {
   this.menuClosed.next(state)
 };

}