import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class LocalService {
	public language:string = 'de';

	constructor(private _translate: TranslateService) {
		this.getLanguage();
	}

	public changeLanguage(value) {
		this.language = value;
		Storage.set({key: 'lang', value: value});
	}

	async getLanguage() {
  		const ret = await Storage.get({ key: 'lang' });
		if(!ret.value) {
			this.language = 'de';
			Storage.set({key: 'lang', value: 'de'});
		} else {
			this.language = ret.value;
		}
		return this.language;
	}
}
